<template>
  <div>
    <page-nav title="问答专区" />
    <div class="faq-list">
      <div v-for="item in list" :key="item.questId" class="faq-item">
        <div class="head">
          <span>用户{{item.questionNick}}的提问:</span>
          <span>{{item.createTime}}</span>
        </div>
        <div class="faq-title">{{item.questionTitle}}</div>
        <div v-if="item.helperList.length == 0" class="faq-answer"><span class="no-answer">暂无回答</span></div>
        <div v-for="answer in item.helperList" :key="answer.helper" class="faq-answer">{{answer.message}}</div>
      </div>
    </div>
    <div class="fix-bottom">
      <van-button type="info" block @click="onAsk">向已购买的用户提问</van-button>
    </div>
  </div>
</template>

<script>
import utils from '@/common/utils';
import Fetch from '@/common/fetch';
import { GetAskAnswerList } from '@/common/interface';
import Vue from 'vue';
import PageNav from '@/components/PageNav';
import { Field, Toast } from 'vant';

Vue.use(Field);
Vue.use(Toast);

export default {
  name: "Faqlist",
  components: { PageNav },
  data() {
    return {
      list: [],
    };
  },
  mounted() {
    this.fetchList();
  },
  methods: {
    fetchList() {
      const id = utils.getQueryString('id') || '';
      const params = {
        productId: id,
        pageNum: 1,
        pageSize: 100,
      };

      Fetch.post(GetAskAnswerList, params).then((res) => {
        this.list = res.list || [];
        this.totalCount = res.total;
      }).catch((err) => {
        Toast.fail(err.msg || '请求失败');
      });
    },

    onAsk() {
      const id = utils.getQueryString('id') || '';
      this.$router.push('/faqs/ask?id=' + id);
    },
  },
}
</script>

<style lang="scss" scoped>
.faq-list {
  background-color: #e8e8ed;
}
.faq-item {
  margin: 10px 0;
  background-color: #fff;
  padding: 0 10px 12px;
}
.faq-item .head {
  position: relative;
  margin-bottom: 10px;
  height: 34px;
  line-height: 34px;
  color: #666;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
}
.faq-title {
  position: relative;
  margin-bottom: 2px;
  padding-left: 20px;
  color: #333;
  font-size: 16px;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.faq-title::before {
  content: "问";
  display: inline-block;
  margin: -2px 5px 0 -20px;
  width: 15px;
  height: 15px;
  line-height: 15px;
  color: #fff;
  font-size: 13px;
  font-weight: 400;
  background-color: #ff9600;
  text-align: center;
  border-radius: 2px;
  vertical-align: middle;
}
.faq-answer {
  position: relative;
  margin-bottom: 5px;
  padding-left: 20px;
  color: #333;
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.faq-answer::before {
  content: "答";
  display: inline-block;
  margin: -2px 5px 0 -20px;
  width: 15px;
  height: 15px;
  line-height: 15px;
  color: #fff;
  font-size: 13px;
  background-color: #18c461;
  text-align: center;
  border-radius: 2px;
  vertical-align: middle;
}
.faq-answer .no-answer {
  color: #999;
}
.fix-bottom {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding-bottom: env(safe-area-inset-bottom);
}
</style>